//Dragable map op mobiel
@include media-breakpoint-down(md) {
  .map {
    position: relative;
    width: 100vw;
    height: 90vh;
    overflow: hidden;

    &-image {
      position: absolute;
    }
  }
}

