.header-top {
  position: absolute;
  z-index: 999;
  width: 100%;

  .left-logo {
    img {
      width: 100%;
    }
  }
}

.mobile-logo {
  img {
    width: 50px;
  }
}

.navbar {
  background-color: $white;
  box-shadow: 5px 0px 5px -4px rgba(0, 0, 0, 0.2);

  &-fixed-left {
    top: 0;
    width: 150px;
    position: absolute;
    border-radius: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 2;

    .navbar-nav > li {
      float: none; /* Cancel default li float: left */
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      bottom: 0;
      top: unset;
      align-items: center;
      height: auto;
      justify-content: space-between;
      overflow: hidden;

    }
  }
  &-collapse {
    text-align: center;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.icon-bar {
  width: 1.7rem;
  height: 3px;
  background-color: $primary;
  display: block;
  transition: all 0.2s;
  margin-top: 4px
}

.navbar-toggler {
  border: none;
  background: transparent !important;

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    width: 1.7rem;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
  }

  &.collapsed {
    .top-bar {
      transform: rotate(0);
    }

    .middle-bar {
      opacity: 1;
    }

    .bottom-bar {
      width: 1rem;
      transform: rotate(0);
    }
  }
}

.nav-link {
  font-weight: bold;
  font-size: 1.25rem;
  font-family: $headings-font-family;
}


