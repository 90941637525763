h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $gray-base;
}

h2, h3, h4, h5, h6,
.h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  font-weight: normal;
}

.h1, h1 {
  font-size: 2.75rem;
}

.h2, h2 {
  font-size: 2rem;
}

@include media-breakpoint-down(md) {
  h1, .h1 {
    margin: 0;
    font-size: 1.5rem;
  }

  .h2, h2 {
    font-size: 1rem;
  }

  h3, .h3 {
    margin: 0;
    font-size: 1rem;
  }

}

p {
  line-height: 1.25rem;
  color: $primary;
  font-family: $font-family-base;
}


a {
  font-size: $font-size-base;
  color: $tertiary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


.station{
  &-title {
  padding: 0 2.5rem;
}
  &-orange {
    color: $orange;
  }
  &-blue {
    color: $blue;
  }
  &-purple {
    color: $purple;
  }
  &-pink {
    color: $pink;
  }
}