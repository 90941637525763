//// SPACING
$spacer: 2.75rem !default;

//// THEMING: COLORS
$primary: #492970;
$secondary: #ACD7E7;
$tertiary: #DC1D6D;


// Colors as word
$purple: $primary;
$blue: #1188c2;
$orange: #ec6e24;
$pink: $tertiary;

$white: #FFFFFF;

$gray-base: #333333;
$gray-100: mix($gray-base, #ffffff, 5%);
$gray-200: mix($gray-base, #ffffff, 10%);
$gray-300: mix($gray-base, #ffffff, 20%);
$gray-400: mix($gray-base, #ffffff, 25%);
$gray-500: mix($gray-base, #ffffff, 40%);
$gray-600: mix($gray-base, #ffffff, 65%);
$gray-700: mix($gray-base, #ffffff, 80%);
$gray-800: mix($gray-base, #ffffff, 90%);
$gray-900: $gray-base;

//// THEMING: FONTS
$font-family-base: "Poppins", Helvetica, Arial, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;
$headings-line-height: 1;
$font-size-xl: $font-size-base * 2.889;
$font-size-lg: $font-size-base * 1.556;
$font-size-md: $font-size-base * 1.333;
$font-size-sm: $font-size-base * .833;
$small-font-size: $font-size-sm;
$paragraph-margin-bottom: $font-size-base * 1.5;

$fa-font-path: "../fonts" !default;


//// List inline padding
$list-inline-padding: 1rem;


//// THEMING: HEADINGS
$h1-font-size: $font-size-base * 3.75;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$headings-font-family: "Poppins", Helvetica, Arial, sans-serif;
$headings-font-weight: 700;
$headings-font-weight-sm: 400;
$headings-color: $white;

//// THEMING: NAVBAR
$navbar-light-color: $gray-900;
$navbar-nav-link-padding-x: 3.125rem / 2;
$navbar-padding-x: 15px;

//// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1500px) !default;

$container-max-widths: (
        sm: 576px,
        md: 768px,
        lg: 1135px,
        xl: 1140px
);


$popover-bg: $orange !default;