.custom-search-input {
  padding: 1rem;
  border: solid 1px $primary;
  border-radius: 2rem;
  background-color: #fff;

  input {
    border: 0;
    box-shadow: none;

    @include media-breakpoint-down(md) {
      font-size: .7rem;
      padding-left: 1.5rem;
    }
  }

  input[type=text] {
    &:focus {
      outline: none;
    }
  }

  i {
    color: $primary;
    position: absolute;
    left: -5px;
  }

  @include media-breakpoint-down(md) {
    padding: 10px;

    .input-group {
      padding: 0;
    }

    i {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}