.modal {
  &-backdrop {
    @include media-breakpoint-down(md) {
      background-color: unset !important;
      &.background {
        background-color: #000 !important;
        transition: .3s;
      }
    }
  }

  &.left {
    .modal {
      &-dialog {
        position: fixed;
        margin: auto;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        @include media-breakpoint-down(md) {
          margin: 2rem;
          bottom: -550px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

          &.open {
            top: 1rem;
            bottom: 0;
            transition: .3s;
          }
        }

        @include media-breakpoint-down(sm) {
          margin: 1rem;
        }
      }

      &-content {
        border: none;
        border-radius: 0;
        height: 100%;
        overflow-y: auto;
      }

      &-body {
        padding: 2.5rem;

        table {
          width: 100% !important;
        }
      }
    }

    &.fade {
      .modal-dialog {
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
      }

      &.in {
        .modal-dialog {
          left: 0;
        }
      }
    }
  }

  &.right {
    .modal {
      &-dialog {
        position: fixed;
        margin: auto;
        height: 100%;
        transform: translate3d(0%, 0, 0);

        @include media-breakpoint-down(md) {
          margin: 2rem;
          bottom: -550px;
        }
      }

      &-content {
        height: 100%;
        overflow-y: auto;
      }

      &-body {
        padding: 2.5rem;
      }
    }

    &.fade {
      .modal-dialog {
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }

      &.in {
        .modal-dialog {
          right: 0;
        }
      }
    }
  }

  &-header {
    border-radius: 0;
    border-bottom: 0;
    padding: 0;
    display: block;

    img {
      width: 100%;
    }
  }

  &-by-line {
    font-family: $headings-font-family;
    color: $primary;
    font-style: italic;
    font-size: 1rem;
  }

  &-content {
    background-color: #FFF;
  }

  &-body {
    img {
      padding: 2rem 0;
      max-width: 100%;
    }
  }

  &-footer {
    padding: 1rem 1rem 1rem 2rem;
    justify-content: flex-start;
    border: none;

    i {
      background-color: $primary;
      color: $white;
      font-size: 1rem;
      border-radius: 50%;
      padding: 0.5rem;
      opacity: 1;

      &:hover {
        background-color:darken($primary, 5%);
      }
    }
  }

  // set editor modal on a higher z-index to fix closing bug
  &.editor {
    z-index: 99999;
  }

  &-mobile-controls {
    flex: 0 0 auto;
    padding: 1rem;

    img {
      align-self: center;
    }
  }
}

.custom-title-input {
  padding: 0.5rem;
  margin: 1rem 0 1rem 0;
  border: solid 1px $gray-300;
  border-radius: .5rem;
  background-color: #fff;

  input {
    border: 0;
    box-shadow: none;
  }

  input[type=text] {
    &:focus {
      outline: none;
    }
  }
}


[aria-label="Insert Link"] {
  z-index: 99999;
}

[aria-label="Insert Image"] {
  z-index: 99999;
}

.prev-next {
  opacity: 1;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;


  @include media-breakpoint-down(md) {
    font-size: 1rem;
    padding: 1rem;
  }
}



// slick modal styling
//.slick {
//  &-slide {
//    margin-left:  15px;
//    margin-right:  15px;
//  }
//
//  &-list {
//    margin-left: -15px;
//    margin-right: -15px;
//    pointer-events: none;
//  }
//
//  &-modal {
//    &-container-absolute {
//      //position: absolute;
//     // z-index: 999;
//    }
//
//    .modal {
//      &-dialog {
//        //position: fixed;
//        margin: auto;
//        height: 100%;
//        transform: translate3d(0%, 0, 0);
//
//        @include media-breakpoint-down(md) {
//          //margin: 2rem;
//          //bottom: -550px;
//          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
//
//          &.open {
//            top: 1rem;
//            bottom: 0;
//            transition: .3s;
//          }
//        }
//      }
//
//      &-content {
//        border: none;
//        border-radius: 0;
//        height: 100%;
//        overflow-y: auto;
//      }
//
//      &-body {
//        padding: 2.5rem;
//      }
//    }
//  }
//}