main {
  height: 100vh;
  padding-top: 135px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("/images/background.png");

  @include media-breakpoint-down(md) {
    height: 90vh;
    padding-top: 0;
  }

  .container {
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
